import * as React from 'react';
import styled from 'styled-components';
import { H1 } from '../components/typography/Heading';
import { rhythm } from '../utils/typography';

const Wrapper = styled.div`
  max-width: 520px;
  margin: 0 auto;
  maxWidth: ${rhythm(24)},
`;

const Error = () => (
  <Wrapper>
    <H1>Whoops... something is not right!</H1>
    <p>
      Apparently something on the platform isn&apos;t working... please shoot me and&nbsp;
      <a href="mailto:admin@sachamorgese.com">email</a>
      &nbsp;and I&apos;ll try to fix it!
    </p>
  </Wrapper>
);

export default Error;
